:root {
  --color-yellow: #FFE445;
  --color-red: #FC3F1D;
  --color-white: #FFFFFF;
  --color-whitesmoke: #F5F5F5;
  --color-black: #333333;
  --color-black-light: #565656;
  --color-gray-dark: #929292;
  --color-gray-middle: #CACACA;
  --color-gray-light: #E3E3E3;

  --shadow-plain: 0px 1px 2px rgba(0, 0, 0, 0.12);
  --shadow-accent: 0px 3px 10px rgba(0, 0, 0, 0.2);
  /* Transition: set by DND */
  /* --transition-data: cubic-bezier(0.2,1,0.1,1) 0.33s; */

  /* Set by utils */
  /* --tag-violet: #EEE1FD;
  --tag-green: #BAF8CF;
  --tag-red: #FED6CC;
  --tag-orange: #FFDCB6;
  --tag-blue: #B8E6FF;
  --tag-light-green: #D8FCB0;
  --tag-dark-blue: #C6D9FF;
  --tag-yellow: #FFF4C7; */
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  overflow-y: overlay;

  font-family: "Yandex Sans Text", 'Segoe UI', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-whitesmoke);
  color: var(--color-black);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
}

a {
  color: inherit;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@media not screen and (hover: none) and (pointer: coarse) {
  /* ::-webkit-scrollbar {
    width: 1rem;
  }

  ::-webkit-scrollbar-track:vertical {
    margin: 75px;
  } */

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3), inset 0 0 0 1rem var(--color-gray-light);
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 1rem var(--color-gray-dark);
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    /* border: solid calc(1rem / 4) transparent; */
    border-radius: 10px;
  }
}